        import React, { useState,useEffect } from 'react';
        import DigifferWallet from 'rishabh-digiffer-wallet-reactjs';
        import './walletComponent.css';
        import { FaSpinner } from 'react-icons/fa';
        import dotenv from "dotenv";
        dotenv.config();
        const WalletComponent = () => {
            const [wallet, setWallet] = useState(null);
            const [balance, setBalance] = useState(null);
            const [balance1, setBalance1] = useState(null);
            const [contractData, setContractData] = useState(null);
            const [writeResponse, setWriteResponse] = useState(null);
            const [loading, setLoading] = useState(false);
            const [loadingBalance, setLoadingBalance] = useState(false);
            const [loadingContract, setLoadingContract] = useState(false);
            const [loadingWrite, setLoadingWrite] = useState(false);
            const [error, setError] = useState(null);

            const config = {
                hostUrl: process.env.REACT_APP_HOST_URL,
                apiKey: process.env.REACT_APP_API_KEY,
                googleClientID:process.env.REACT_APP_GOOGLE_CLIENT_ID
            };

            const digifferWallet = new DigifferWallet(config);

            const handleLogin = () => {
                setLoading(true);
                setError(null);
                digifferWallet.initLogin((response) => {
                    console.log('Google login response:', response);
                    if (response) {
                        const { subID, walletAddress } = response;
                        console.log('subId:', subID);
                        if (subID) {
                            localStorage.setItem('sub_id', subID);
                        } else {
                            console.error('subId is undefined in response');
                        }
                        setWallet(walletAddress);
                    } else {
                        console.error('Login response is undefined');
                    }
                    setLoading(false);
                });
            };
            const handleLogout = () => {
                localStorage.removeItem('sub_id');
                setWallet(null);
                setBalance(null);
                setBalance1(null);
                setContractData(null);
                setWriteResponse(null);
                setError(null);
            };
            const getSubId = () => {
                const subId = localStorage.getItem('sub_id');
                console.log('Retrieved subId:', subId);
                return subId;
            };

            const getBalance = async () => {
                setLoadingBalance(true);
                setError(null);
                try {
                    const subID = getSubId();
                    if (!subID) {
                        throw new Error('No Sub Id found');
                    }
                    const requestData = { sub_id : subID };
                    const requestData1 = { sub_id : subID, token: process.env.REACT_APP_TOKEN }
                    const balance = await digifferWallet.getBalance(requestData);
                    const balance1 = await digifferWallet.getBalance(requestData1);
                //  console.log("Balances availaible are",balance,balance1);
                    setBalance(balance.formattedAmount);
                    setBalance1(balance1.formattedAmount);      
                    console.log(balance,balance1);
                } catch (error) {
                    console.error('Failed to get balance:', error);
                    setError('Failed to get balance. Please try again.');
                } finally {
                    setLoadingBalance(false);
                }
            };

            const readContract = async () => {
                setLoadingContract(true);
                setError(null);
                try {
                    const subId = getSubId();
                    if (!subId) {
                        throw new Error('No sub_id found');
                    }
                    const contractABI = JSON.parse(process.env.REACT_APP_CONTRACT_ABI);
                //  console.log("Contract abi is",contractABI);
                    const requestData = {
                        sub_id: subId,
                        contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
                        contractABI:contractABI,
                        functionName: "sales",
                        functionArguments:[1]
                    };
                    const contractData = await digifferWallet.readContract(requestData);
                    setContractData(contractData);
                    console.log(contractData);
                    // console.log('Request Data:', requestData);
                
                    // const response = await digifferWallet.readContract(requestData);
                    // console.log('Raw Response:', response);
                    
                    // // Ensure all BigInt values are converted to strings
                    // const serializedData = JSON.parse(JSON.stringify(response, (key, value) =>
                    //     typeof value === 'bigint' ? value.toString() : value
                    // ));
            
                    // setContractData(serializedData);
                    // console.log('Serialized Data:', serializedData);
                } catch (error) {
                    console.log('Failed to read contract:', error);
                    setError('Failed to read contract. Please try again.');
                } finally {
                    setLoadingContract(false);
                }
            };
            const writeContract = async () => {
                setLoadingWrite(true);
                setError(null);
                try {
                    const subId = getSubId();
                    if (!subId) {
                        throw new Error('No sub_id found');
                    }
                    const contractABI = JSON.parse(process.env.REACT_APP_CONTRACT_ABI);
                    const requestData = {
                        sub_id: subId,
                        contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
                        contractABI:contractABI,
                        functionName: 'updateSaleStatus',
                        functionArguments: [1,false]
                    };
                    const writeResponse = await digifferWallet.writeContract(requestData);
                    setWriteResponse(writeResponse);
                    console.log(writeResponse);
                } catch (error) {
                    console.error('Failed to write contract:', error);
                    setError('Failed to write contract. Please try again.');
                } finally {
                    setLoadingWrite(false);
                }
            };

            return (
                <div className="wallet-container">
                    {wallet ? (
                        <>
                            <button className="action-button" onClick={handleLogout}>
                                Logout
                            </button>
                            {wallet && <div className="info">Wallet Address: {wallet}</div>}
                            <button className="action-button" onClick={getBalance} disabled={loadingBalance}>
                                {loadingBalance ? <FaSpinner className="loading-spinner" /> : 'Get Balance'}
                            </button>
                            <button className="action-button" onClick={readContract} disabled={loadingContract}>
                                {loadingContract ? <FaSpinner className="loading-spinner" /> : 'Read Contract'}
                            </button>
                            <button className="action-button" onClick={writeContract} disabled={loadingWrite}>
                                {loadingWrite ? <FaSpinner className="loading-spinner" /> : 'Write Contract'}
                            </button>
                        </>
                    ) : (
                        <button className="action-button" onClick={handleLogin}>
                            Login with Google
                        </button>
                    )}
                    {error && <div className="error-message">{error}</div>}
                    {balance !== null && <div className="info">Native Token Balance: {balance}</div>}
                    {balance1 !== null && <div className="info">Token Balance: {balance1}</div>}
                    {contractData && <div className="info">Contract Data: {JSON.stringify(contractData, null, 2)}</div>}
                    {writeResponse && <div className="info">Write Response: {JSON.stringify(writeResponse, null, 2)}</div>}
                </div>
            );
        };


        export default WalletComponent;