import React from 'react';
import WalletComponent from './WalletComponent';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Digiffer Wallet Integration</h1>
                <WalletComponent />
            </header>
        </div>
    );
}

export default App;
